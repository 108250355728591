.navigation-header {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: $phoneBig) {
    font-size: 0.8em;
  }

  &--grey {
    color: $grey-2;
  }

  &-button {
    border: none;
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: $grey-2;
      margin-left: 10px;
      &::after {
        content: '';
        margin-left: 10px;
        border-right: 1px solid $grey-2;
      }
    }
    @media (max-width: $phoneBig) {
      div svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
