.scheduler__content {
  margin: 3em 0 0 0;
  ul.scheduler__main-list {
    li.scheduler__main-list__item {
      width: 100%;
      margin-bottom: 60px;
      > div {
        border-bottom: 2px solid $xiaomi;
        padding-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 26px;
        width: 100%;
        span {
          font-weight: bold;
          font-size: 14px;
          margin-right: 74px;
        }
        time {
          font-weight: 500;
          font-size: 14px;
          color: $text-grey;
          position: relative;
        }
      }
      li {
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 50px;
        background: $white;
        cursor: pointer;
        time {
          color: $text-grey2;
          width: 100%;
          position: relative;
          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 50px;
            height: 1px;
            width: calc(100% - 50px);
            top: 0;
            bottom: 0;
            margin: auto;
            border-bottom: 1px dotted #bfbfbf;
          }
        }
        > div {
          border-radius: 10px;
          background: $blue-light;
          padding: 10px;
          width: 100%;
          margin: 4px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid transparent;
          transition: border-color 0.2s ease-in-out;
          > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .icons-wrapper {
            margin-right: 30px;
            div svg {
              width: 25px;
              height: 25px;
              margin: 0 5px;
            }
          }
          div {
            img {
              width: 60px;
              height: 60px;
              border-radius: 10px;
              background: $white;
              object-fit: cover;
              object-position: center;
              margin-right: 30px;
            }
            span {
              font-size: 16px;
              font-weight: 600;
              color: $black;
            }
            time {
              font-size: 14px;
              font-weight: 600;
              color: $text-grey;
              position: relative;
              &:after {
                content: none;
              }
            }
          }
          &.active {
            border: 1px solid $xiaomi;
            background: $xiaomi-light;
          }
          &:hover {
            border-color: $xiaomi;
          }
        }
        &.time-only {
          margin-left: 0;
          height: 6px;
        }
      }
    }
  }
}
