.profile-aside-item {
  display: flex;
  align-items: center;
  margin: 1em 0;
  padding: 1em 0;
  border-bottom: 1px solid $grey-light;
  cursor: pointer;
  gap: 10px;

  span {
    font-size: 14px;
  }

  div svg {
    height: auto;
    max-width: 20px;
  }
  &--logout {
    margin: auto 0 0 0;
    border-bottom: none;
    border-top: 1px solid $grey-light;
    span {
      color: $tesla;
    }
  }
  &--settings {
    div svg {
      transform: rotate(90deg);
    }
  }
  &--active {
    span {
      font-weight: bold;
    }
  }
  &__arrow {
    background-color: $grey-light;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 0 0 auto;

    svg {
      height: 15px !important;
      stroke-width: 5;
      transform: rotate(0deg) !important;
    }
    &--networking {
      background-color: transparent;
    }
  }
}

.gamification--aside {
  width: 100%;
}

@media (max-width: 680px) {
  .gamification--aside {
    flex: 1;
    justify-content: center;
    align-items: center;
    margin: 0 2px 0 2px;

    span {
      font-size: 13px;
      text-align: center;
    }
  }
}
