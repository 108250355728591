@import 'common/index';
@import 'components/index';
@import 'sections/index';
@import 'pages/index';

ul {
  list-style: none;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
}

@media (max-width: $desktop) {
  .App {
    flex-direction: column;
  }
}

main {
  width: 100%;
  height: calc(100vh - 50px);
  > div {
    display: flex;
  }
}
.nopadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
