@import '../../../scss/common/index';

.gamification-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.gamification-button {
  background-color: $xiaomi;
  border-radius: 5px;
  border: none;
  color: $white;
  height: 43px;
  padding: 10px 28px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  max-width: 165px;

  &:hover {
    background-color: darken($xiaomi, 5%);
  }
}

.gamification-button--form {
  margin: 0 0 0 auto;
}

.gamification-button--red {
  background-color: $xiaomi;

  &:hover {
    background-color: darken($xiaomi, 5%);
  }
}

.gamification-button-exhibitor {
  background-color: $white;
  color: $dark;
  width: 220px;

  &:hover {
    background-color: lighten($xiaomi, 25%);
  }
}

.gamification-button-exhibitor--active {
  background-color: lighten($xiaomi, 25%);
}

.gamification-aside {
  width: 100%;
  padding: 60px 15px 15px 15px;
}

.gamification-ranking {
  display: flex;
  flex-direction: column;
  // max-width: 100vw;
  max-height: 75vh;
  overflow-x: auto;
  overflow-y: auto;
  padding: 0 2px;
}

.gamification-ranking-user {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 3px #0000001a;
  border-radius: 5px;
  background-color: #fff;
  height: 52px;
  min-height: 52px;
  margin-bottom: 12px;
  min-width: 300px;
  width: 100%;

  span {
    &:first-child {
      display: flex;
      justify-content: center;
      min-width: 40px;
      max-width: 40px;

      span {
        background-color: #f7f8fc;
        border-radius: 50%;
        min-width: 28px;
        max-width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:nth-child(2) {
      min-width: 260px;
      width: 260px;
    }
    flex: 1;
    min-width: 120px;
    width: 120px;
  }
}

.gamification-ranking-user--active {
  background-color: $xiaomi-light;
}

.gamification-ranking-user-titles {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  margin-bottom: 12px;
  min-width: 300px;

  span {
    &:first-child {
      display: flex;
      justify-content: center;
      min-width: 40px;
      max-width: 40px;
      span {
        background-color: #f7f8fc;
        border-radius: 50%;
      }
    }

    &:nth-child(2) {
      min-width: 260px;
      width: 260px;
    }

    color: #c6c6c6;
    flex: 1;
    min-width: 120px;
    width: 120px;
  }
}

.gamification-exhibitors-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px 0;

  overflow-x: auto;
  .gamification-button {
    margin: 5px;
  }
}

.gamification-link {
  width: 90%;
  height: 50px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
  background-color: $xiaomi;
}

.gamification-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.main__container--mobile {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #fff;
}

.gamification-aside-wrapper {
  display: flex;
  // background-color: #fff;
  flex-direction: column;
  width: 220px;
  min-width: 220px;
  padding: 30px 15px 15px 15px;

  // .main__aside {
  //   width: auto;
  //   min-width: au
  // }
}

.gamification-icon {
  max-width: 600px;
  margin: 0 auto;
}

.gamification-aside-button {
  height: 36px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;

  span {
    font-size: 14px;
    font-weight: 600;
  }
}

.gamification-aside-button-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  background-color: #e9eaf2;
  border-radius: 5px;
}

.gamification-aside-button-icon-wrapper--active {
  background-color: $xiaomi;
}

@media (max-width: 1280px) {
  .gamification-aside {
    flex: 60% 1;
  }
}

@media (max-width: 680px) {
  .main__container--mobile {
    flex-direction: column;
  }

  .gamification-aside-wrapper {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    padding: 0px 5px 0px 3px;
    margin: 0;

    .gamification-aside-button {
      margin: 5px 5px 0 5px;
      white-space: nowrap;
    }

    .gamification-aside-button--active {
      border-bottom: 3px solid $xiaomi;
    }
  }

  .gamification-ranking-user {
    span {
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        display: none;
      }
      &:nth-child(6) {
        display: none;
      }
    }
  }

  .gamification-ranking-user-titles {
    span {
      &:nth-child(4) {
        display: none;
      }
      &:nth-child(5) {
        display: none;
      }
      &:nth-child(6) {
        display: none;
      }
    }
  }
}

@media (max-width: 380px) {
  .gamification-ranking-user {
    span {
      &:nth-child(2) {
        min-width: 200px;
        width: 200px;
      }
      &:nth-child(3) {
        min-width: 25px;
        width: 25px;
      }
    }
  }

  .gamification-ranking-user-titles {
    span {
      &:nth-child(2) {
        min-width: 200px;
        width: 200px;
      }
      &:nth-child(3) {
        min-width: 15px;
        width: 15px;
      }
    }
  }
}

@media (max-width: 300px) {
  .gamification-ranking-user {
    span {
      &:nth-child(2) {
        min-width: 180px;
        width: 180px;
      }
      &:nth-child(3) {
        min-width: 15px;
        width: 15px;
      }
    }
  }

  .gamification-ranking-user-titles {
    span {
      &:nth-child(2) {
        min-width: 180px;
        width: 180px;
      }
      &:nth-child(3) {
        min-width: 15px;
        width: 15px;
      }
    }
  }
}
