.news-item {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 50px;
    margin: 40px 0px;
    width: 100%;
    @media (max-width: $tabletSmall) {
      flex-direction: column;
    }
    &--left {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 20px;
      min-width: 300px;
      width: 100%;
      flex: 1;
      @media (max-width: $tabletSmall) {
        min-width: auto;
      }

      h1 {
        font-size: 28px;
        margin: 0 auto;
      }

      span {
        font-size: 14px;
        color: $text-grey;
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 2;
      gap: 40px;

      p {
        color: $text-grey;
        font-size: 18px;
        text-align: justify;
        text-justify: inter-word;
      }

      img {
        border-radius: 5px;
        width: 80%;
        height: auto;
      }
    }
  }
  &__date {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 0 auto;
  }
}

.news-item-person {
  display: flex;
  img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
  &__column {
    display: flex;
    flex-direction: column;
  }
}
