.meetinga-header {
  position: fixed;
  background: linear-gradient(264deg, #00a1ed, #2968bc, #4c3892, #5a2683);
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.meetinga-header__info {
  display: flex;
  align-items: center;
  /* gap: 10px; */
}

.meetinga-header__info img {
  width: 50px;
  height: 50px;
  margin-right: 25px;
}

.meetinga-header__cancel {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  /* gap: 10px; */
}

.meetinga-header__cancel div svg {
  width: 15px;
  height: 15px;
  margin-left: 15px;
}

.meetinga-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px 0 50px 0;
}

.meetinga-main__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1360px;
}

.meetinga-main__wrapper h1 {
  margin: 0;
  padding: 1em;
  font-size: 48px;
}

.meetinga-main__form {
  width: 95%;
  padding: 0 10px;
}

.meetinga-main__form ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.meetinga-main__form ul li {
  margin: 2em 0;
  padding: 30px 40px;
  border-radius: 20px;
  box-shadow: 0px 25px 60px #00000014;
}

.meetinga-main__form button {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 700;
  padding: 0 1em;
  align-items: center;
  height: 60px;
  width: 200px;
  float: right;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: linear-gradient(264deg, #00a1ed, #2968bc, #4c3892, #5a2683);
}

.meetinga-main__form button div svg {
  width: 15px;
  height: 15px;
}

.meetinga-form-item {
  display: flex;
  justify-content: space-between;
}

.meetinga-form-item__text {
  font-size: 16px;
  text-align: justify;
  text-justify: inter-word;
  color: #6e6c71;
}

.meetinga-form-item__left {
  display: flex;
  flex-direction: column;

  form {
    margin: 20px 0 0 0;

    input {
      width: 60%;
    }
  }
}

.meetinga-form-item__left h2 {
  padding: 0;
  margin: 0 0 1em 0;
}

.meetinga-form-item__right {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 30px; */
}

.meetinga-form-item-right__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 150px;
}

.vertical-line {
  border: 2px solid #f4f4f4;
  height: 100%;
  margin: 0 15px;
}

.meetinga-form-price {
  font-size: 28px;
  font-weight: bold;
  color: #f56837;
}

/* checkbox starts here */

.meetinga-form-item-right__right {
  color: #aaaaaa;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  max-width: 160px;
  height: 100px;
  font-size: 20px;
}

.meetinga-form-item-right__right input[type='radio'] {
  position: absolute;
  visibility: hidden;
}

.meetinga-form-item-right__right label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 20px;
  padding: 25px 25px 25px 80px;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

.meetinga-form-item-right__right:hover label {
  color: #f56837;
}

.meetinga-form-item-right__right .check {
  display: block;
  position: absolute;
  border: 5px solid #aaaaaa;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 35px;
  left: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.meetinga-form-item-right__right:hover .check {
  border: 5px solid #f56837;
}

.meetinga-form-item-right__right .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: -5px;
  left: -5px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.meetinga-form-item-right__right input[type='radio']:checked ~ .check {
  border: 5px solid #f56837;
}

.meetinga-form-item-right__right input[type='radio']:checked ~ .check::before {
  background: #f56837;
}

.meetinga-form-item-right__right input[type='radio']:checked ~ label {
  color: #f56837;
}

/* checkbox ends here */

.meetinga-main-finished {
  display: flex;
  margin: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  border: 4px solid #2ed76a;
  border-radius: 25px;
  box-shadow: 0px 25px 60px #00000014;
}

.meetinga-main-finished p {
  color: #6e6c71;
}

.meetinga-main-finished-image-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #61e18e;
  margin: 0 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meetinga-main-finished-image-wrapper div svg {
  width: 50px;
  height: 50px;
}

.meetinga-main-address {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60px;
}

.meetinga-main-address__text {
  background-color: #f6f6f6;
  width: 100%;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #6e6c71;
}

.meetinga-main-address button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(264deg, #00a1ed, #2968bc, #4c3892, #5a2683);
  min-width: 200px;
  padding: 0 10px;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.meetinga-main-address button div {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.meetinga-main-address button div svg {
  width: 15px;
  height: 15px;
}

.meetinga-main-info {
  text-align: center;
  margin: 50px 1em;
  color: #1dd45e;
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.meetinga-main-info div svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.meetinga-main-quit {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #252533;
  margin: 0 auto 20px 1em;
  height: 60px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  min-width: 150px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.meetinga-main-quit div svg {
  width: 15px;
  height: 15px;
}

@media (max-width: 850px) {
  .meetinga-main-address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: auto;
  }

  .meetinga-main-address__text {
    height: 60px;
    width: 100%;
  }

  .meetinga-main-address button {
    margin-top: 10px;
    height: 60px;
  }

  .meetinga-form-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .meetinga-main-info {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 650px) {
  .meetinga-form-item__right {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 30px; */
  }

  .vertical-line {
    border: 2px solid #f4f4f4;
    height: 0;
    margin: 30px 0 0 0;
    width: 100%;
  }
}
