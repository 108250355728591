h1 {
  font-size: 62px;
  font-weight: 600;
}

h3 {
  font-size: 28px;
  font-weight: 600;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 15px;
}

a {
  color: inherit !important;
}
