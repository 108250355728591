.tab-button:hover {
  --icon-color: #{$text-grey};

  .icon {
    &--white-hover {
      --icon-color: #{$white};
    }

    &--grey-hover {
      --icon-color: #{$text-grey};
    }

    &--lightGrey {
      --icon-color: #{$grey-2};
    }

    &--black-hover {
      --icon-color: #{$black};
    }

    &--xiaomi-hover {
      --icon-color: #{$xiaomi};
    }

    &--lightGreen-hover {
      --icon-color: #{$lightGreen};
    }
  }

  .icon--fill {
    svg {
      fill: var(--icon-color);
      line {
        stroke: var(--icon-color);
      }
      path {
        fill: var(--icon-color);
      }
      g path {
        fill: var(--icon-color);
      }
    }
    .icon--stroke {
      svg {
        stroke: var(--icon-color);
        line {
          stroke: var(--icon-color);
        }
        path {
          stroke: var(--icon-color);
        }
        g > * {
          stroke: var(--icon-color);
        }
      }
    }
  }
}

.icon {
  --icon-color: #{$text-grey};
  --hover-icon-color: var(--icon-color);

  svg {
    path {
      transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
    }
    g > * {
      transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
    }
  }

  &--pointer {
    cursor: pointer;
  }

  &--white {
    --icon-color: #{$white};
    &-hover {
      --hover-icon-color: #{$white};
    }
  }

  &--grey {
    --icon-color: #{$text-grey};
    &-hover {
      --hover-icon-color: #{$text-grey};
    }
  }

  &--lightGrey {
    --icon-color: #{$grey-2};
    &-hover {
      --hover-icon-color: #{$grey-2};
    }
  }

  &--black {
    --icon-color: #{$black};
    &-hover {
      --hover-icon-color: #{$black};
    }
  }

  &--xiaomi {
    --icon-color: #{$xiaomi};
    &-hover {
      --hover-icon-color: #{$xiaomi};
    }
  }

  &--tesla {
    --icon-color: #{$tesla};
    &-hover {
      --hover-icon-color: #{$tesla};
    }
  }

  &--lightGreen {
    --icon-color: #{$lightGreen};
    &-hover {
      --hover-icon-color: #{$lightGreen};
    }
  }

  &--fill svg {
    fill: var(--icon-color);
    line {
      stroke: var(--icon-color);
    }
    path {
      fill: var(--icon-color);
    }
    g path {
      fill: var(--icon-color);
    }
    &:hover {
      fill: var(--hover-icon-color);
      line {
        stroke: var(--icon-color);
      }
      path {
        fill: var(--hover-icon-color);
      }
      g > * {
        fill: var(--hover-icon-color);
      }
    }
  }

  &--stroke svg {
    stroke: var(--icon-color);
    line {
      stroke: var(--icon-color);
    }
    path {
      stroke: var(--icon-color);
    }
    g > * {
      stroke: var(--icon-color);
    }
    &:hover {
      stroke: var(--hover-icon-color);
      line {
        stroke: var(--icon-color);
      }
      path {
        stroke: var(--icon-color);
      }
      g > * {
        stroke: var(--hover-icon-color);
      }
    }
  }
}
