.information {
  min-width: 400px;
  width: 80%;
  // min-height: calc(100vh - 100px);
  overflow-y: auto;
  position: sticky;
  // top: 100px;
  left: 0;
  display: flex;
  background: white;
  @media (max-width: $desktop) {
    min-width: auto;
    width: 100%;
    flex: 40%;
  }
  // @media (max-width: $phoneBig) {
  //   min-width: auto;
  //   width: 150px;
  //   flex: auto;
  // }
  h3 {
    color: $black;
  }
  time.information__time {
    font-size: 14px;
    color: $text-grey;
    margin-bottom: 22px;
    display: block;
    font-weight: 600;
  }
  p {
    margin-top: 30px;
    margin-bottom: 40px;
    display: block;
    font-size: 15px;
    color: $text-grey;
  }
  h6 {
    margin-bottom: 22px;
    margin-top: 34px;
  }
  .information__section {
    padding: 18px 0;
    border-bottom: 1px solid $blue-light2;
    span {
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      line-height: 1.2;
    }
    small {
      color: $text-grey;
      font-size: 12px;
      font-weight: 600;
    }
    &--person {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 14px;
      }
    }
    &--buttons {
      margin: 1em 0 1em 0;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      @media (max-width: $phoneBig) {
        align-items: flex-end;
        flex-direction: column;
        button {
          order: 1;
        }
      }
    }
  }
  .information__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    div {
      display: flex;
      align-items: center;

      div {
        svg {
          background: $grey-light;
          width: 35px;
          height: 35px;
          padding: 5px;
          border-radius: 4px;
        }
        &:nth-of-type(2) {
          margin-left: 10px;
        }
      }
      span {
        border: 2px solid $grey-light;
        border-radius: 4px;
        height: 35px;
        display: block;
        display: flex;
        align-items: center;
        align-content: center;
        padding: 0 10px;
        color: $text-grey;
        font-weight: 600;
        margin-right: -4px;
      }
    }
  }
}
