.networking-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 1em 0 0 0;
  padding: 30px 40px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  div {
    svg {
      width: 25px;
      height: 25px;
    }
  }
  &__container {
    position: relative;
    height: 45px;
    width: 100%;
  }
  &__input {
    border: 1px solid $grey-2;
    height: 45px;
    padding: 0 10px;
    width: 100%;
    &:disabled {
      cursor: not-allowed;
    }
  }
  &__icon {
    position: absolute;
    right: 7.5px;
    top: 10px;
  }
  button {
    width: 110px;
    height: 45px;
    border-radius: 5px;
    border: none;
    background-color: $xiaomi;
    color: $white;
    &:disabled {
      background-color: $text-grey2;
      cursor: not-allowed;
    }
  }
}
