.main__container {
  background-color: white;
  display: flex;
  min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  &--networking {
    background-color: $blue-light;
    @media (max-width: $desktop) {
      min-height: calc(100vh);
      max-height: calc(100vh);
    }
  }
  @media (max-width: $desktop) {
    flex-wrap: wrap;
  }
  @media (max-width: $tabletBig) {
    min-height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
  }
}

.main__wrapper {
  background-color: white;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  max-width: calc(100vw - 308px);
  &::after {
    content: '';
    display: block;
    min-height: 25px;
    width: 100%;
  }
  &--networking {
    &::after {
      min-height: 100px;
    }
  }
  @media (max-width: $desktop) {
    max-width: none;
    flex: 60%;
  }
  @media (max-width: $tabletSmall) {
    padding: 10px 20px;
  }
}

.not-visible {
  display: none;
}

.main__aside {
  background-color: $blue-light;
  width: 400px;
  min-width: 400px;
  padding: 40px 30px;
  // max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: $tabletBig) {
    width: 100%;
    min-width: auto;
  }
  &--networking {
    background-color: $white;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 160px);
    padding: 0;
    @media (max-width: 1420px) {
      width: 300px;
      min-width: 300px;
    }
    @media (max-width: $tabletBig) {
      width: 100%;
      min-width: auto;
    }
  }
  &::after {
    content: '';
    display: block;
    min-height: 25px;
    width: 100%;
  }
}

.main__top {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: $tabletSmall) {
    flex-direction: column;
  }
  &--networking {
    padding-bottom: 20px;
    border-bottom: 1px solid $blue-light2;
    @media (max-width: 1420px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media (max-width: $tabletSmall) {
    font-size: 32px;
  }
}

.main__title {
  flex: 1;
  h1 {
    font-size: 62px;
    margin: 26px 0;
    @media (max-width: $tabletSmall) {
      font-size: 32px;
    }
  }
  &--networking {
    flex: 0 1 auto;
    margin: 0 4em 0 0;
  }
}

.main__settings {
  display: flex;
  align-items: center;
  button {
    margin-left: 45px;
    background: transparent;
    border: 0;
    cursor: unset;
    &:nth-of-type(1) {
      margin-left: -45px;
    }
    &:nth-of-type(2) {
      background: $orange;
      border-radius: 50%;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:focus {
      outline: none;
    }
  }
}

.main-aside-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: $black;
  font-weight: bold;
  font-size: 14px;
  margin: 0.5em 0;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    background-color: $xiaomi;
    color: $white;
  }
  &--active {
    background-color: $xiaomi;
    color: $white;
    // order: -1;
  }
  &__text-wrapper {
    flex: 1;
  }
  &__link {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 10px;
    color: $black;
    text-decoration: none;
    &:hover {
      transition: color 0.3s ease-in-out;
      color: $white;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 36px;
    min-width: 36px;
    height: 36px;
    min-height: 36px;
    &--grey {
      background-color: $grey-light;
    }
    &--orange {
      background-color: $orange;
    }
    &--yellow {
      background-color: $yellow;
    }
    &--blue {
      background-color: $blue;
    }
    &--purple {
      background-color: $purple;
    }
  }
}

.main__search {
  display: flex;
  position: relative;
  div {
    position: absolute;
    right: 5px;
    top: 25%;
    @media (max-width: $tabletSmall) {
      top: 0;
    }
  }
}

.main-aside-search-wrapper {
  display: flex;
  button {
    margin-right: 5px;
  }
}

.main__search-input {
  height: 45px;
  max-width: 350px;
  min-width: 100px;
  width: 100%;
  padding: 10px 30px 10px 10px;
  border-radius: 5px;
  border: 1px solid $text-grey2;
  @media (max-width: $tabletSmall) {
    font-size: 14px;
  }
}
