.access-map {
  height: 100%;
  min-height: 400px;
  margin-bottom: 5em;
}

.access-text {
  margin: 1em 0;
  display: flex;
  gap: 10px;
}

.addresses {
  margin-top: 30px;
}
