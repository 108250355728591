.lecturers {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
}

.lecturer-person {
    background-color: $grey-light;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
}

