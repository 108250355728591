.call-widget {
  background: $black-dark;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  img {
    margin-right: 12px;
    width: 81px;
    height: 81px;
    padding: 14px;
    border: 2px solid $xiaomi;
    border-radius: 50%;
    background: $black-light;
  }
  label {
    width: 100%;
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      time {
        color: $xiaomi;
      }
      span {
        color: $white;
        padding-left: 15px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: $green;
        }
      }
    }

    progress[value] {
      -webkit-appearance: none;
      appearance: none;
      border-radius: 3px;
      max-height: 5px;
      width: 100%;
    }
    progress[value]::-webkit-progress-bar {
      background-color: $black-light;
      border-radius: 3px;
      height: 5px;
    }
    progress[value]::-webkit-progress-value {
      background: $xiaomi;
      border-radius: 3px;
      height: 5px;
    }
  }
}
