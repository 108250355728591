header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 60px;
  border-bottom: 1px solid $black-border;
  position: sticky;
  // z-index: 3;
  top: 0;
  background-color: $white;
  height: 100px;
  > div {
    > img {
      width: 125px;
      height: auto;
      padding-right: 18px;
      margin-right: 18px;
      border-right: 1px solid $border-grey-light;
    }
  }

  .header-logo {
    img {
      width: 100px;
    }
  }

  .header-profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    gap: 10px;
    font-weight: 600;
    margin: 0 1.5em 0 0;

    &__notifications {
      border: 0;
      background: transparent;
      position: relative;
      span {
        position: absolute;
        background: $red;
        border-radius: 50%;
        color: $white;
        width: 20px;
        height: 20px;
        font-size: 12px;
        font-weight: 600;
        border: 2px solid $white;
        right: 0px;
        top: 0;
      }
      img {
        width: 25px;
      }
    }

    &__user {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        border: 0;
        background: transparent;
        position: relative;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    &__image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    &__link {
      text-decoration: none;
      color: $black;
      span {
        margin: 0 1em 0 0;
      }
    }
  }
}

.header-menu-item {
  display: flex;
  gap: 10px;

  div svg {
    width: 20px;
    height: 20px;
  }
}

.header--mobile {
  background-color: $black;
  color: $white;
  border: none;
  padding: 10px 0 0 0;
  justify-content: flex-end;
  .header-logo {
    display: none;
  }
  .header-profile__icon {
    color: #fff;
  }
}

.header-language-button {
  background-color: transparent;
  border: none;

  &:disabled {
    cursor: not-allowed;
  }
}
