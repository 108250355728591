.transmission__container ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5%;
  li {
    width: 100%;
  }
  @media (min-width: 1250px) {
    li {
      width: 49.75%;
    }
  }
  @media (min-width: 1500px) {
    li {
      width: 33%;
    }
  }
  @media (min-width: 1930px) {
    li {
      width: 24.6%;
    }
  }
}

.transmission__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 0 0 1em 0;
}

.transmission__person {
  position: relative;
  margin-top: 0.5%;
  img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: $white;
    background-color: $black;
    padding: 0 0.5em;
    opacity: 0.9;
    border-radius: 5px;
  }
}

.transmission__bar {
  background-color: $grey-light;
  height: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transmission-bar-button {
  background-color: $white;
  border: none;
  color: $black;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  margin: 0 0.5em;
  box-shadow: $shadow 0px 1px 5px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 5px;
  }

  &__text {
    font-size: 14px;
    &--grey {
      color: $text-grey2;
    }
  }
}
