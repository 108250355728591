@import '../../../../scss/common/index';

.gamification-send-code-text {
  color: #252523;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.gamification-send-code-subtext {
  color: #6e6c71;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
}

.gamification-send-code-wrapper {
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
  flex-wrap: wrap;
}

.gamification-send-code-input-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 334px;
  width: 100%;
  margin-right: 10px;

  input {
    margin-bottom: 0;
    min-height: 44px;
    height: 44px;
    background: #fff;
    border: 1px solid #e5e5e5;
  }
}

.gamification-send-code-score-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #f7f8fc;
  box-shadow: 0px 5px 6px #00000005;
  border-radius: 5px;
  margin: 10px 0 0 auto;
  padding: 24px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}

.gamification-send-code-score-text {
  color: #6e6c71;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
}

.gamification-send-code-score-points {
  color: #252533;
  font-size: 22px;
  font-weight: 600;
  line-height: 18px;
}

@media (max-width: 550px) {
  .gamification-send-code-input-wrapper {
    max-width: 280px;
  }

  .gamification-send-code-wrapper {
    .gamification-button {
      width: 120px;
      font-size: 14px;
      padding: 10px 6px;
    }
  }
}

@media (max-width: 445px) {
  .gamification-send-code-input-wrapper {
    max-width: 235px;
  }

  .gamification-send-code-wrapper {
    .gamification-button {
      width: 120px;
      font-size: 14px;
      padding: 10px 6px;
    }
  }

  .gamification-send-code-score-wrapper {
    margin: 10px auto 0 auto;
  }
}

@media (max-width: 400px) {
  .gamification-send-code-wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .gamification-send-code-input-wrapper {
    max-width: none;
    width: 100%;
  }
  .gamification-send-code-wrapper {
    .gamification-button {
      width: 120px;
      font-size: 14px;
      padding: 10px 6px;
      margin: 10px 10px 0 auto;
    }
  }
}
