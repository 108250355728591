@import '../../../../scss/common/index';

.gamification-title {
  color: #252523;
  font-size: 40px;
  font-weight: 600;
  line-height: 70px;
}

@media (max-width: 680px) {
  .gamification-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
  }
}
