.notes-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__textarea {
    width: 100%;
    padding: 10px 30px 10px 10px;
    border-radius: 5px;
    border: 1px solid $text-grey2;
    resize: none;
  }
  &__input {
    height: 45px;
    width: 100%;
    padding: 10px 30px 10px 10px;
    border-radius: 5px;
    border: 1px solid $text-grey2;
  }
  &__button {
    align-self: flex-end;
    height: 45px;
    width: 80px;
    border-radius: 5px;
    border: 1px solid $text-grey2;
    background-color: $white;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    &:hover {
      background-color: $xiaomi;
      color: $white;
    }
    &:disabled {
      background-color: $white;
      color: $black;
      cursor: not-allowed;
    }
  }
}

.notes-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.note {
  display: flex;
  flex-direction: column;
  width: 325px;
  height: 500px;
  padding: 1em;
  background-color: $blue-light2;
  word-wrap: break-word;
  gap: 20px;
  &--active {
    transition: background-color 0.3s ease-in-out;
    background-color: $xiaomi-light;
  }
  &__title {
    font-size: 1.6em;
  }
  &__text {
    margin: 1em 0 0 0;
  }
  &__items {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__icons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: auto;
  }
  &__button {
    border: none;
    background-color: inherit;
  }
  hr {
    width: 100%;
    color: $black-border;
    border-color: $black-border;
  }
}
