.localization {
  display: flex;
  align-items: center;

  &__mark {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey-light;
    border-radius: 10px;
    width: 45px;
    height: 45px;
  }

  &__content {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 1em;
    span {
      font-weight: bold;
      &:nth-child(2) {
        font-size: 12px;
        color: $text-grey;
      }
    }
  }
  &__arrow {
    background-color: $grey-light;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    svg {
      // width: 15px;
      height: 15px;
      stroke-width: 5;
    }
    &--networking {
      background-color: transparent;
    }
  }
}
