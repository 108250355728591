.download__link {
    color: $black;
    &:hover {
        color: $black;
        text-decoration: none;
    }
}

.download__container {
    display: flex;
    align-items: center;
    padding: 0 1em;
    background-color: $blue-light2;
    border-radius: 5px;
    height: 35px;

    div svg {
        width: 20px;
        height: 24px;   
    }
}