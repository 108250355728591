.profile {
  width: 50%;
  h1 {
    font-size: 28px;
  }
  &__image {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0 0 2em 0;
    img {
      width: 150px;
      height: 150px;
    }
    &--edit {
      position: absolute;
      bottom: 0;
      left: 52.5%;
      background-color: #0071c5;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      width: 40px;
      height: 40px;
      input {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99999;
        opacity: 0;
      }
    }
  }
  &__delete {
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: $blue-light;
    padding: 10px;
    border-radius: 5px;
    h2 {
      font-size: 14px;
    }
    span {
      color: $text-grey;
    }
    button {
      margin: 2em auto 0 0;
      border-radius: 5px;
      border: none;
      background-color: $tesla;
      padding: 0 1em;
      height: 45px;
      color: $white;
    }
  }
  &__submit {
    background-color: $xiaomi;
    color: $white;
    margin: 50px auto 0 0;
    border: none;
    border-radius: 5px;
    height: 60px;
    min-height: 60px;
    width: 200px;
  }
}

.profile-checkbox {
  margin: 4em 0 0 0;
  &--extended {
    display: flex;
    flex-direction: column;
    margin: 2em 0;
    border-bottom: 1px solid $border-grey;
    padding: 1em 0;
    p {
      margin: 0 0 0 2.4em;
      color: $text-grey;
    }
  }
  &__inputs {
    display: flex;
  }
  &__btn {
    border: none;
    background-color: transparent;
    margin: 0 0 0 auto;
    rotate: 90deg;
    padding: 0;
    &--active {
      transform: rotate(180deg);
    }
  }
  &__extended {
    a {
      margin: 0 0 0 35px;
      color: blue !important;
      text-decoration: underline !important;
    }
  }
}

.profile-login-item {
  flex: 1;
  width: 100%;
}

.profile-login-btn {
  flex: 0 1 auto;
  justify-content: center;
  width: auto;
  button {
    margin: 0.5em 0 0 0;
    border-radius: 5px;
    border: none;
    background-color: $black;
    color: $white;
    padding: 0 1em;
    height: 60px;
  }
}

.profile-wrapper {
  form {
    display: flex;
    flex-direction: column;
    margin: 4em 0 1em 0;
  }

  label {
    font-weight: 600;
  }
}
