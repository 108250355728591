@import '../../../../scss/common/index';

.gamification-exhibitor-tile {
  box-shadow: 0px 2px 3px #0000001a;
  border-radius: 5px;
  width: 250px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: none;
}

.gamification-exhibitor-tile-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.gamification-exhibitor-tile-image-wrapper--active {
  border: 2px solid #11d633;
}

.gamification-exhibitor-tile-image {
  width: auto;
  height: auto;
  max-width: 140px;
  max-height: 40px;
}

.gamification-exhibitor-is-voted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #f7f8fc;
  min-height: 50px;
  // background-color: $xiaomi;
}

.gamification-exhibitor-is-voted--active {
  border-top: none;
  background-color: #11d633;
}

.gamification-tiles-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.gamification-info {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.gamification-info-list {
  display: flex;
  flex-direction: column;

  li {
    margin-left: 40px;
    list-style-type: disc;
  }
}

@media (max-width: 680px) {
  .gamification-exhibitor-tile {
    width: 160px;
    height: 170px;
    div {
      span {
        inline-size: 150px;
        overflow-wrap: break-word;
        text-align: center;
      }
    }
  }
  .gamification-exhibitor-is-voted {
    min-height: 60px;
  }
  .gamification-tiles-wrapper {
    justify-content: center;
    align-items: center;
  }
}
