.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 600px;
  min-height: 600px;
  gap: 20px;
  padding: 1em;

  &--question {
    min-height: 400px;
  }

  &-question__content {
    display: flex;
    gap: 40px;
  }

  &-question__questions {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    max-height: 350px;
  }

  @media (max-width: $tabletSmall) {
    min-width: auto;
    min-height: auto;
    height: 100%;
    width: 100%;
    max-width: 800px;
    max-height: 800px;
    overflow-y: auto;

    &-question__content {
      flex-direction: column;
      align-items: center;
    }

    &-question__write {
      order: 2;
    }

    &-question__questions {
      order: 1;
    }
  }

  &-rate {
    width: 100%;
    &__text {
      color: $text-grey;
      font-weight: bold;
    }
    display: flex;
    gap: 10px;
    flex-direction: column;
    hr {
      width: 100%;
      border-color: $blue-light;
    }
    input {
      height: 40px;
      min-height: 40px;
      background-color: $white;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      box-shadow: #0000001a 0px 3px 8px;
      padding-left: 20px;
    }
  }
  &__send-btn {
    color: $white;
    background-color: $xiaomi;
    border: none;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    min-height: 30px;
    margin: auto 0 0 auto;
  }
  form {
    width: 100%;
    height: 100%;
  }
}

.modal-question {
  display: flex;
  width: 100%;
  background-color: $blue-light;
  border-radius: 5px;
  &__text {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 20px;
    justify-content: center;
    h2 {
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }
    span {
      color: $text-grey;
    }
  }
  &__likes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $xiaomi;
    color: $white;
    width: 40px;
    border-radius: 0 5px 5px 0;
    padding: 10px 0;
    button {
      border: none;
      background-color: inherit;

      div svg {
        width: 14px;
        height: 14px;
      }
    }
  }
  &__write {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    max-width: 400px;
    border-radius: 5px;
    span {
      color: $black;
      font-size: 22px;
      font-weight: bold;
    }
    textarea {
      resize: none;
      padding: 10px;
      height: 200px;
      box-shadow: 0px 3px 8px #0000001a;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
    }
  }
  &__btn {
    margin: auto auto 0 0;
  }
}

.asking-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  min-height: 200px;
  &__send-btn {
    color: $white;
    background-color: $xiaomi;
    border: none;
    border-radius: 5px;
    width: 130px;
    height: 40px;
    margin: auto 0 auto 0;
  }
  div {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
  }
  p {
    text-align: center;
    max-width: 400px;
  }
}

.asking-modal--agenda-link {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #f7f8fc;
  text-decoration: underline;
  color: blue !important;
}

.asking-modal--agenda-logo {
  max-width: 200px;
  margin-bottom: 16px;
}
