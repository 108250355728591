.tiles {
  &__wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5%;
    li {
      width: 49.5%;
    }
    @media (min-width: 1250px) {
      li {
        width: 33%;
      }
    }
    @media (min-width: 1500px) {
      li {
        width: 24.5%;
      }
    }
    @media (max-width: $phoneBig) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      li {
        width: auto;
      }
    }
  }
  &-item {
    position: relative;
    color: $white;
    font-weight: bold;
    margin-top: 1.5%;
    width: 100%;
    height: 100%;
    &__image {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    &__person {
      position: absolute;
      top: 20px;
      left: 20px;
      border: 2px solid $white;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      z-index: 1;
    }
    &__date {
      position: absolute;
      top: 20px;
      right: 20px;
      text-shadow: 1px 1px $black;
      z-index: 1;
    }
    &__text {
      position: absolute;
      bottom: 20px;
      padding-right: 20px;
      left: 20px;
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 5px;
      background: linear-gradient(#00000000, #000000cc);
      width: 100%;
      height: 75%;
    }
  }
}
